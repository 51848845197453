import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import "./question.css";

const CrypticHunt = () => {
  const [hint, setHint] = useState("He who taught it checkers.");
  const [flag, setFlag] = useState("");
  const [timer, setTimer] = useState(35 * 60 + 41);
  const [errorMessage, setErrorMessage] = useState("");
  const [hintUsed, setHintUsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Use location hook to get the current URL

  useEffect(() => {
    // Prevent going back to the previous page
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.go(1);
    };

    // Redirect if the page is accessed directly or via back navigation
    if (location.pathname === "/leaderboard" && window.history.state) {
      navigate("/question/platform"); // Redirect to another page if coming from leaderboard
    }
  }, [navigate, location.pathname]);

  const nextQuestion = async () => {
    if (flag.trim() === "Arthur Samuel") {
      try {
        const token = localStorage.getItem('token'); // Get token from localStorage

        await axios.post('https://skillop.in/api/mlevent/points/add', {
          points: 6,
        }, {
          headers: {
            'Authorization': `Bearer ${token}` // Include token in headers
          }
        });

        toast.success("Points added successfully!"); // Toast for success
        navigate("/question/platform");
      } catch (error) {
        console.error("Error adding points:", error);
        toast.error("Error adding points. Please try again."); // Toast for error
      }
    } else {
      setErrorMessage("Wrong answer! Please try again.");
      toast.error("Wrong answer! Please try again."); // Toast for wrong answer
    }
  };

const showHint = async () => {
  if (!hintUsed) {
    try {
      const token = localStorage.getItem('token'); // Get token from localStorage

      await axios.post('https://skillop.in/api/mlevent/points/deduct', {}, {
        headers: {
          'Authorization': `Bearer ${token}` // Include token in headers
        }
      });

      setHintUsed(true);
      toast.info(`Hint used! ${hint} - 2 points deducted.`, {
        position: "top-right",
        autoClose: false,
        closeButton: true,
        theme: "colored",
      }); // Toast for hint and points deduction
    } catch (error) {
      console.error("Error using hint:", error);
      toast.error("Error using hint. Please try again."); // Toast for error
    }
  } else {
    toast.warning("Hint already used."); // Toast for already used hint
  }
};

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="cryptic-hunt">
      <ToastContainer />
      <div className="header">
        <img src="/skillop-logo.png" alt="Skillop" className="logo" />
        <h1>Cryptic Hunt On ML</h1>
      </div>
      <div className="content">
        <div className="challenge-section">
          <h2>Q1.</h2>
          <div className="question-content">
            <p>
              I taught me one game,<br />
              the world took it to fame.<br />
              I got learning in me,<br />
              who coined my name.
            </p>
            <div className="right-side-content">
              <span className="points">6 Pts</span>
              <span className="difficulty">Easy</span>
              <button className="hint-button" onClick={showHint}>Hint</button>
            </div>
          </div>
          <input
            type="text"
            placeholder="Enter the flag"
            value={flag}
            onChange={(e) => setFlag(e.target.value)}
            className="flag-input"
          />
          <button onClick={nextQuestion}>Submit</button>
        </div>
        <div className="leaderboard-section">
          <Link to="/leaderboard" className="view-leaderboard-button">
            View Leaderboard
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CrypticHunt;
